import { MsrcAuthContextConfig } from '@msrceng/auth';

/** SPA-router route for the home page, relative to this application */
export const portalHomeHref = '/';
//portal name is passed in by localization
/** Name of the overall site this portal lives under */
export const siteName = 'MSRC';
/** URL of the site's root, relative to whole site */
export const siteHomeHref = '/';
//TODO: change this to the secrel email
export const supportEmailUrl = 'msrc-portals-support@microsoft.com';

export interface ISugConfig extends MsrcAuthContextConfig {
  apiDocsUrl: string;
}
