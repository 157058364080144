import i18next, { use, changeLanguage } from 'i18next';
import Backend from 'i18next-fetch-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import 'moment/min/locales';
import { startsWith, size } from 'lodash';
// import { fallback } from './bakedin/en-us';

const loadPath = (lng: string, ns: string): string => {
  if (startsWith(lng, 'zh') && size(lng) > 2) {
    return `${process.env.PUBLIC_URL}/locale/${lng.toLowerCase()}/${ns}.json`;
  }
  return `${process.env.PUBLIC_URL}/locale/${lng.slice(0, 2)}/${ns}.json`;
};

export const i18n =
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      // resources: fallback,
      fallbackLng: 'en-US',
      // debug: true,
      returnObjects: true,
      returnNull: false,
      partialBundledLanguages: true, //allows us to load some languages locally
      backend: {
        // path where resources get loaded from, or a function
        // returning a path:
        // function(lngs, namespaces) { return customPath; }
        // the returned path will interpolate lng, ns if provided like giving a static path
        loadPath,
        // path to post missing resources
        // addPath: 'locale/add/{{lng}}/{{ns}}',
        // your backend server supports multiloading
        // /locales/resources.json?lng=de+en&ns=ns1+ns2
        allowMultiLoading: false, // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading
        lowercase: true,
      },
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
        format: (value, format, lng) => {
          if (format === 'uppercase') {
            return value.toUpperCase();
          }
          const asDate = moment(value);
          if (asDate.isValid() || value instanceof Date) {
            if (asDate.isValid()) {
              return asDate.format(format);
            }
            return '';
          }
          return value;
        },
      },
    });

i18next.on('languageChanged', lng => {
  if (lng.startsWith('es')) {
    changeLanguage('en');
  }
  moment.locale(lng);
});

// This is added to disable the false positive of t functions returning null.
// https://www.i18next.com/overview/typescript#argument-of-type-defaulttfuncreturn-is-not-assignable-to-parameter-of-type-xyz
declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}
